import { Box, IconButton, Typography, useTheme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { ColorModeContext, tokens } from '../../theme';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '../../context/AuthContext';
import { io } from 'socket.io-client';
import api from '../../lib/axios';

// Initialize Socket.IO
const socket = io(`${process.env.REACT_APP_API_URL}`);

const Topbar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const { handleLogout } = useAuth();
  const [authTokenStatus, setAuthTokenStatus] = useState(Boolean);
  const token = localStorage.getItem('token');

  const playNotificationSound = (() => {
    const audio = new Audio('/notification.mp3');
    let isPlaying = false;

    const play = () => {
      if (!isPlaying) {
        isPlaying = true;
        audio.currentTime = 0;
        audio.play();
        audio.addEventListener(
          'ended',
          () => {
            isPlaying = false;
          },
          { once: true }
        );
      }
    };

    return play;
  })();

  const shouldPlaySound = () => {
    const lastPlayed = sessionStorage.getItem('lastPlayed');
    const now = Date.now();

    if (!lastPlayed || now - lastPlayed > 2000) {
      // Check if 1 second has passed since last play
      sessionStorage.setItem('lastPlayed', now);
      return true;
    }

    return false;
  };

  const getCurrentAuthStatus = async () => {
    try {
      const response = await api.get('/api/get-current-auth-status', {
        headers: {
          Authorization: token,
        },
      });
      setAuthTokenStatus(response.data.currentAuthStatus);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getCurrentAuthStatus();

    socket.on('newRequest', () => {
      if (shouldPlaySound()) {
        playNotificationSound();
      }
    });

    // Listen for authFailure event
    socket.on('authStatus', (data) => {
      if (data) {
        setAuthTokenStatus(data.authStatus);
      }
    });

    return () => {
      socket.off('newRequest');
      socket.off('authStatus');
    };
  }, []);

  socket.on('newRequest', () => {
    if (shouldPlaySound()) {
      playNotificationSound();
    }
  });

  return (
    <Box
      display="flex"
      justifyContent="flex-end"
      alignItems="center" // Align items vertically center
      p={2}
      borderBottom={`1px solid ${colors.grey[700]}`}
    >
      {/* Display Auth Status */}
      <Box display="flex" alignItems="center" mr={2}>
        {authTokenStatus ? (
          <Typography variant="body1" color="green">
            Token Valid
            {/* Alternatively, use an icon:
            <CheckCircleIcon style={{ color: 'green', marginLeft: '4px' }} /> */}
          </Typography>
        ) : (
          <Typography variant="body1" color="red">
            Token Invalid
            {/* Alternatively, use an icon:
            <CancelIcon style={{ color: 'red', marginLeft: '4px' }} /> */}
          </Typography>
        )}
      </Box>

      {/* ICONS */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? (
            <DarkModeOutlinedIcon />
          ) : (
            <LightModeOutlinedIcon />
          )}
        </IconButton>
        <IconButton onClick={handleLogout}>
          <LogoutIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default Topbar;
