import { Box, useTheme } from '@mui/material';
import BoxesLoader from '../utils/BoxLoader';
import { tokens } from '../theme';

const Loader = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Box
      display="flex"
      height="73vh"
      justifyContent="center"
      alignItems="center"
      bgcolor={colors.primary[400]}
      border={`2px solid ${colors.blueAccent[700]}`}
      borderRadius="8px"
    >
      <BoxesLoader
        boxColor={colors.blueAccent[500]}
        shadowColor={colors.primary[500]}
        style={{ marginBottom: '20px' }}
        desktopSize={'128px'}
        mobileSize={'80px'}
      />
    </Box>
  );
};

export default Loader;
