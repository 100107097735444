import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import api from '../../lib/axios';
import moment from 'moment';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import InfoIcon from '@mui/icons-material/Info';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from '../../context/AuthContext';
import { verifyToken } from '../../lib/axios';
import { io } from 'socket.io-client';
import { Link } from 'react-router-dom';
import ClientDetailsCard from '../../components/ClientDetailsCard';
import Loader from '../../components/Loader';

const socket = io(process.env.REACT_APP_API_URL);

const BonusHistory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    {
      field: 'userId',
      headerName: 'User Id',
      flex: 0.6,
      renderCell: ({ row: { userId } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Link
              to={`https://backoffice.betconstruct.com/#/customers/detail/${userId}`}
              style={{ textDecoration: 'none', color: '#4caf50' }}
              target="_blank"
            >
              <Typography>{userId}</Typography>
            </Link>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'employee',
      headerName: 'Employee Name',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'bonusType',
      headerName: 'Bonus Type',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'updatedAt',
      headerName: 'Updated At',
      flex: 1,
      disableClickEventBubbling: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      renderCell: ({ row: { status, wheelCode } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              p={0.8}
              paddingX={4}
              display="flex"
              justifyContent="center"
              alignItems="center" // Ensure content is vertically centered
              backgroundColor={
                status === 'accepted'
                  ? colors.greenAccent[600]
                  : status === 'rejected'
                  ? '#f44336'
                  : colors.grey[700]
              }
              borderRadius="4px"
              width="150px" // Set a fixed width for consistency
            >
              {status === 'accepted' && <CheckIcon />}
              {status === 'rejected' && <CloseIcon />}
              {status === 'pending' && <AccessTimeIcon />}
              <Typography
                style={{ textTransform: 'capitalize' }}
                color={colors.grey[100]}
                sx={{ ml: '5px', flexGrow: 1, textAlign: 'center' }} // Center the text and allow it to grow within the box
              >
                {wheelCode !== null ? wheelCode : status}
              </Typography>
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'info',
      headerName: 'Info',
      flex: 0.3,
      renderCell: ({ row: { userId, username } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#4BB543',
              },
            }}
            onClick={() => handleInfoOpen(userId, username)}
          >
            <InfoIcon />
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [lastRequests, setLastRequests] = useState([]);
  const { handleLogout } = useAuth();
  const [selectedUsername, setSelectedUsername] = useState('');
  const [selectedUserId, setSelectedUserId] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [infoOpen, setInfoOpen] = useState(false);

  const fetchLastRequests = useCallback(async () => {
    setIsLoading(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    const response = await api.get('/api/last-requests', {
      headers: {
        Authorization: token,
      },
    });
    const formattedlastRequests = response.data.lastRequests.map((request) => ({
      ...request,
      lastTimeUserTakeBonus: formatDate(request.lastTimeUserTakeBonus),
      createdAt: formatDate(request.createdAt),
      updatedAt: formatDate(request.updatedAt),
    }));
    setLastRequests(formattedlastRequests);
    setIsLoading(false);
  }, [token]);

  useEffect(() => {
    fetchLastRequests();

    socket.on('updateRequest', () => {
      fetchLastRequests();
    });
  }, []);

  const handleInfoOpen = (userId, username) => {
    setSelectedUserId(userId);
    setSelectedUsername(username);
    setInfoOpen(!infoOpen);
  };

  const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');
  return (
    <Box m="20px">
      <Header
        title="Bonus History"
        subtitle="Last Bonus Requests"
        color={colors.greenAccent[400]}
        mt={0}
      />
      <Box
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rows={lastRequests}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
          />
        )}
      </Box>
      {infoOpen && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '44rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
            maxHeight: '90vh',
            overflowY: 'auto',
          }}
        >
          <ClientDetailsCard
            infoClose={setInfoOpen}
            newUserId={selectedUserId}
            newUsername={selectedUsername}
          />
        </Box>
      )}
    </Box>
  );
};

export default BonusHistory;
