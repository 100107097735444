export const bonusCategories = ['spor', 'casino', 'diger'];
export const bonusTypes = [
  { name: 'Freespin', id: '5' },
  { name: 'Wagering Bonus', id: '2' },
  { name: 'Freebet', id: '0' },
];
export const wheelPrizes = [
  { id: 1, label: '100 TL Nakit' },
  { id: 2, label: 'Kazanamadın' },
  { id: 3, label: '15 TL Nakit' },
  { id: 4, label: '50 Freespin' },
  { id: 5, label: '75 Freespin' },
  { id: 6, label: 'Kazanamadın' },
  { id: 7, label: '100 Freespin' },
  { id: 8, label: '75 TL Nakit' },
  { id: 9, label: '50 TL Nakit' },
  { id: 10, label: '50 Freebet' },
  { id: 11, label: '10 Freespin' },
  { id: 12, label: '100 Freebet' },
  { id: 13, label: '20 Freespin' },
  { id: 14, label: '10 TL Nakit' },
  { id: 15, label: '200 Freespin' },
];
export const uniqueCodes = [
  {
    id: 2944,
    code: 'PKWR2944',
    used: true,
    prize_label: '15 TL Nakit',
    date: '2024-10-28T10:56:57.053Z',
    kullanıcı_adı: 'ahmet58',
  },
  {
    id: 2943,
    code: 'CHMT2943',
    used: true,
    prize_label: '75 TL Nakit',
    date: '2024-10-28T10:56:43.678Z',
    kullanıcı_adı: 'kumarbazarlanmaz30',
  },
  {
    id: 2942,
    code: 'CFNZ2942',
    used: false,
    prize_label: '600 Freespin',
    date: '2024-10-28T10:56:20.756Z',
    kullanıcı_adı: null,
  },
  {
    id: 2941,
    code: 'LEKC2941',
    used: false,
    prize_label: '600 Freespin',
    date: '2024-10-28T10:56:20.459Z',
    kullanıcı_adı: null,
  },
  {
    id: 2940,
    code: 'ZKFY2940',
    used: true,
    prize_label: '100 Freespin',
    date: '2024-10-28T10:56:13.586Z',
    kullanıcı_adı: 'milyonercenk01',
  },
  {
    id: 2939,
    code: 'GJLL2939',
    used: false,
    prize_label: '100 Freespin',
    date: '2024-10-28T10:56:13.230Z',
    kullanıcı_adı: null,
  },
  {
    id: 2938,
    code: 'NTPV2938',
    used: true,
    prize_label: '75 Freespin',
    date: '2024-10-28T10:56:08.903Z',
    kullanıcı_adı: 'askdhbsa',
  },
  {
    id: 2937,
    code: 'ZPMK2937',
    used: false,
    prize_label: '75 Freespin',
    date: '2024-10-28T10:55:20.242Z',
    kullanıcı_adı: null,
  },
  {
    id: 2936,
    code: 'LQAX2936',
    used: true,
    prize_label: '50 Freespin',
    date: '2024-10-28T10:54:22.178Z',
    kullanıcı_adı: 'naberrrrr',
  },
  {
    id: 2935,
    code: 'TWWS2935',
    used: true,
    prize_label: '50 Freespin',
    date: '2024-10-28T10:53:57.537Z',
    kullanıcı_adı: 'semihcenkfani24',
  },
  {
    id: 2934,
    code: 'LRCT2934',
    used: false,
    prize_label: '50 Freespin',
    date: '2024-10-26T13:53:48.673Z',
    kullanıcı_adı: null,
  },
  {
    id: 2933,
    code: 'EYAQ2933',
    used: false,
    prize_label: '600 Freespin',
    date: '2024-10-26T13:45:56.197Z',
    kullanıcı_adı: null,
  },
  {
    id: 2932,
    code: 'NFST2932',
    used: false,
    prize_label: 'Kazanamadın',
    date: '2024-10-26T13:41:00.426Z',
    kullanıcı_adı: null,
  },
  {
    id: 2931,
    code: 'HVLQ2931',
    used: true,
    prize_label: '75 TL Nakit',
    date: '2024-10-26T13:38:47.165Z',
    kullanıcı_adı: 'askjfdna',
  },
  {
    id: 2930,
    code: 'YRKD2930',
    used: true,
    prize_label: '50 Freespin',
    date: '2024-10-26T13:29:50.389Z',
    kullanıcı_adı: 'alsjdnlsaf',
  },
  {
    id: 2929,
    code: 'HAHN2929',
    used: true,
    prize_label: '100 Freespin',
    date: '2024-10-26T13:25:56.695Z',
    kullanıcı_adı: 'leonardo',
  },
  {
    id: 2928,
    code: 'RXBH2928',
    used: true,
    prize_label: '75 TL Nakit',
    date: '2024-10-26T13:08:11.253Z',
    kullanıcı_adı: 'asdsagka',
  },
  {
    id: 2927,
    code: 'HDBL2927',
    used: true,
    prize_label: '50 TL Nakit',
    date: '2024-10-26T12:57:51.315Z',
    kullanıcı_adı: 'leonardo',
  },
  {
    id: 2926,
    code: 'LMXN2926',
    used: true,
    prize_label: '75 TL Nakit',
    date: '2024-10-26T12:56:37.094Z',
    kullanıcı_adı: 'ashdjbknj',
  },
  {
    id: 2925,
    code: 'LJKP2925',
    used: false,
    prize_label: '75 Freespin',
    date: '2024-10-26T12:54:54.577Z',
    kullanıcı_adı: null,
  },
];
