import React, { useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@mui/material';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { useAuth } from '../../context/AuthContext';
import { verifyToken } from '../../lib/axios';
import { uniqueCodes, wheelPrizes } from '../../constants';
import { useAlert } from '../../lib/useAlert';

const PrizeCodes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    {
      field: 'prize_label',
      headerName: 'Prize Label',
      flex: 0.5,
      disableClickEventBubbling: true,
    },
    {
      field: 'date',
      headerName: 'Date',
      flex: 0.4,
      disableClickEventBubbling: true,
    },
    {
      field: 'code',
      headerName: 'Prize Code',
      cellClassName: 'name-column--cell',
      flex: 0.5,
      renderCell: ({ row: { code } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            marginLeft={1.5}
          >
            <Typography>{code}</Typography>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'used',
      headerName: 'Used - Username',
      flex: 0.3,
      renderCell: ({ row: { used, kullanıcı_adı } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              p={0.8}
              paddingX={4}
              display="flex"
              justifyContent="center"
              alignItems="center"
              backgroundColor={
                used === true
                  ? colors.greenAccent[600]
                  : used === false
                  ? '#f44336'
                  : colors.grey[700]
              }
              borderRadius="4px"
              width="150px"
            >
              {used === true && <Typography>{kullanıcı_adı}</Typography>}
              {used === false && <CloseIcon />}
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [prizeCodes, setPrizeCodes] = useState(() =>
    uniqueCodes.map((request) => ({
      ...request,
      date: moment(request.date).format('DD.MM.YYYY HH:mm:ss'),
    }))
  );
  const [inputValue, setInputValue] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const showAlert = useAlert();
  const { handleLogout } = useAuth();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  const handleClickOpen = () => {
    setDialogOpen(true);
  };

  const handleGenerate = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    const upperAlpha = 'ABCDEFGHJKLMNPQRSTVWXYZ';
    const numbers = '0123456789';

    function generateRandomString() {
      let randomStr = '';
      for (let i = 0; i < 4; i++) {
        randomStr += upperAlpha.charAt(
          Math.floor(Math.random() * upperAlpha.length)
        );
      }
      for (let i = 0; i < 4; i++) {
        randomStr += numbers.charAt(Math.floor(Math.random() * numbers.length));
      }

      return randomStr;
    }

    const generatedCode = generateRandomString();

    const newCodeObject = {
      id: prizeCodes[0].id + 1,
      code: generatedCode,
      used: false,
      prize_label: inputValue,
      date: formatDate(Date.now()),
      kullanıcı_adı: null,
    };

    setPrizeCodes((prevPrizeCodes) => [newCodeObject, ...prevPrizeCodes]);
    handleAlert('Unique Code created successfully!');
    handleClose();
    setIsGenerating(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Bonus History"
          subtitle="Last Bonus Requests"
          color={colors.greenAccent[400]}
          mt={0}
        />
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            width: 35,
            height: 35,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleClickOpen()}
        >
          {dialogOpen ? (
            <RemoveIcon sx={{ color: '#6870fa' }} />
          ) : (
            <AddIcon sx={{ color: '#6870fa' }} />
          )}
        </Box>
      </Box>
      <Box
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        <DataGrid
          checkboxSelection
          disableRowSelectionOnClick
          initialState={{
            sorting: {
              sortModel: [{ field: 'id', sort: 'desc' }],
            },
            pagination: { paginationModel: { pageSize: 25 } },
          }}
          rows={prizeCodes}
          columns={columns}
          pageSizeOptions={[10, 25, 50]}
        />
      </Box>
      <Dialog
        open={dialogOpen}
        onClose={handleClose}
        PaperProps={{
          sx: {
            width: '20vw', // Set custom width
            height: '25vh', // Set custom height
            minHeight: '200px',
            minWidth: '150px',
          },
        }}
      >
        <DialogTitle>Generate Wheel Code</DialogTitle>
        <DialogContent>
          <DialogContentText>Select the wheel prize</DialogContentText>
          <Box display="flex" flexDirection="column">
            <FormControl fullWidth variant="standard">
              <InputLabel>Select Prize</InputLabel>
              <Select
                value={inputValue} // State to store the selected prize
                onChange={(e) => setInputValue(e.target.value)} // Function to update the selected prize
                label="Select Prize"
              >
                {wheelPrizes.map((prize) => (
                  <MenuItem key={prize.id} value={prize.label}>
                    {prize.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            width="100%"
            marginLeft={1}
            marginRight={1}
          >
            <Button
              disabled={isGenerating}
              onClick={handleGenerate}
              color="inherit"
            >
              {isGenerating ? 'Generating...' : 'Generate'}
            </Button>
            <Button onClick={handleClose} color="inherit">
              Cancel
            </Button>
          </Box>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default PrizeCodes;
