import React, { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
  useTheme,
} from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import api from '../../lib/axios';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';
import { useAlert } from '../../lib/useAlert';
import { verifyToken } from '../../lib/axios';
import RegisterCard from '../../components/RegisterCard';
import { io } from 'socket.io-client';
import Loader from '../../components/Loader';

const ManageEmployees = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const socketRef = useRef(null);
  const columns = [
    { field: 'id', headerName: 'ID', flex: 0.3 },
    {
      field: 'username',
      headerName: 'Username',
      flex: 0.5,
      disableClickEventBubbling: true,
    },
    {
      field: 'name',
      headerName: 'Employee Name',
      flex: 0.5,
      disableClickEventBubbling: true,
    },
    {
      field: 'role',
      headerName: 'Role',
      flex: 0.5,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Typography
              color={
                role === 'admin' ? colors.greenAccent[600] : colors.grey[100]
              }
              sx={{
                textTransform: 'uppercase',
              }}
            >
              {role}
            </Typography>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'twofa_secret',
      headerName: 'OTP Secret',
      flex: 1.3,
      renderCell: ({ row: { id, twofa_secret } }) => {
        return (
          <Box
            width={1}
            display="flex"
            flexDirection="row"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            {twofa_secret === null ? (
              <Box
                width={1}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                height={1}
                alignItems="center"
              >
                <Typography>There is no 2fa secret</Typography>
              </Box>
            ) : (
              <Box
                width={1}
                display="flex"
                flexDirection="row"
                justifyContent="flex-start"
                height={1}
                alignItems="center"
              >
                <Typography paddingRight="10px">{twofa_secret}</Typography>
                <Button
                  variant="outlined"
                  color="warning"
                  onClick={() => handleAreYouSure2fa(id)}
                >
                  DEL
                </Button>
              </Box>
            )}
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'createdAt',
      headerName: 'Created At',
      flex: 0.5,
      disableClickEventBubbling: true,
    },
    {
      field: 'isOnline',
      headerName: 'Status',
      flex: 0.35,
      renderCell: ({ row: { isOnline } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Box
              p={0.6}
              display="flex"
              justifyContent="center"
              alignItems="center" // Ensure content is vertically centered
              backgroundColor={
                isOnline ? colors.greenAccent[700] : colors.redAccent[600]
              }
              borderRadius="4px"
              width="150px" // Set a fixed width for consistency
            >
              <Typography
                color={colors.grey[100]}
                sx={{ ml: '5px', flexGrow: 1, textAlign: 'center' }} // Center the text and allow it to grow within the box
              >
                {isOnline ? 'ONLINE' : 'OFFLINE'}
              </Typography>
            </Box>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 0.3,
      renderCell: ({ row: { id } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#7a0012',
              },
            }}
            onClick={() => handleAreYouSure(id)}
          >
            <DeleteOutlineIcon />
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [employees, setEmployees] = useState([]);
  const [showRegister, setShowRegister] = useState(false);
  const [areYouSure, setAreYouSure] = useState(false);
  const [areYouSure2fa, setAreYouSure2fa] = useState(false);
  const [areYouSure2faBool, setAreYouSure2faBool] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const [otpBool, setOtpBool] = useState(true);
  const [id, setId] = useState(Number);
  const { handleLogout, isAdmin } = useAuth();
  const showAlert = useAlert();

  const fetchEmployeesList = async () => {
    setIsLoadingData(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    const response = await api.get('/api/auth/get-employees', {
      headers: {
        Authorization: token,
      },
    });
    const formattedEmployees = response.data.employees.map((request) => ({
      ...request,
      createdAt: formatDate(request.createdAt),
    }));
    setEmployees(formattedEmployees);
    setIsLoadingData(false);
  };

  useEffect(() => {
    fetchEmployeesList();
    getOtpBool();

    if (!socketRef.current) {
      socketRef.current = io(`${process.env.REACT_APP_API_URL}`);
    }

    socketRef.current.on('user-status', () => {
      fetchEmployeesList();
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.off('user-status');
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  // Get boolean for OTP
  const getOtpBool = async () => {
    setIsLoading(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    try {
      const response = await api.get('/api/auth/get-otp-bool', {
        headers: {
          Authorization: token,
        },
      });
      setOtpBool(response.data.otpBool);
    } catch (error) {
      console.error('Error in getOtp:', error);
      handleAlert('Error getting otp knowladge, please refresh the page.');
    } finally {
      setIsLoading(false);
    }
  };

  const otpBoolChange = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    try {
      await api.get('/api/auth/change-otp-bool', {
        headers: {
          Authorization: token,
        },
      });
      getOtpBool();
      handleAreYouSureClose();
      handleAlert(
        otpBool
          ? '2FA AUTHENTICATION DEACTIVATED'
          : '2FA AUTHENTICATION ACTIVATED'
      );
    } catch (error) {
      console.error('Error in getOtp:', error);
      handleAlert('Error getting otp knowladge, please refresh the page.');
    }
  };

  const deleteEmployee = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    try {
      const response = await api.post(
        '/api/auth/delete-employee',
        { id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAreYouSure(false);
      handleAlert(response.data.message);
      fetchEmployeesList();
    } catch (error) {
      console.error('Error in handleReject:', error);
      handleAlert('Error deleting employee, please try again.');
    }
  };
  const deleteEmployee2fa = async () => {
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    if (!isAdmin) return;
    try {
      const response = await api.post(
        '/api/auth/delete-employee-twofa-byid',
        { id: id },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      setAreYouSure(false);
      setAreYouSure2fa(false);
      handleAlert(response.data.message);
      fetchEmployeesList();
    } catch (error) {
      console.error('Error in handleReject:', error);
      handleAlert("Error deleting employee's 2fa secret, please try again.");
    }
  };

  const handleClickOpen = () => {
    setShowRegister(!showRegister);
  };

  const handleAreYouSure = (id) => {
    setId(id);
    setAreYouSure(true);
  };
  const handleAreYouSure2fa = (id) => {
    setId(id);
    setAreYouSure2fa(true);
    setAreYouSure(true);
  };
  const handleAreYouSure2faBool = () => {
    setAreYouSure2faBool(true);
    setAreYouSure(true);
  };

  const handleAreYouSureClose = () => {
    setAreYouSure(false);
    setAreYouSure2fa(false);
    setAreYouSure2faBool(false);
  };

  const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Manage Employees"
          subtitle="Employee functions"
          color={colors.greenAccent[400]}
          mt={0}
        />
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            width: 35,
            height: 35,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleClickOpen()}
        >
          {showRegister ? (
            <RemoveIcon sx={{ color: '#6870fa' }} />
          ) : (
            <AddIcon sx={{ color: '#6870fa' }} />
          )}
        </Box>
      </Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        width={1}
      >
        {!isLoading && (
          <Button
            variant="outlined"
            color={!otpBool ? 'success' : 'warning'}
            onClick={handleAreYouSure2faBool}
          >
            {!otpBool
              ? 'ACTIVATE 2FA AUTHENTICATION'
              : 'DEACTIVATE 2FA AUTHENTICATION'}
          </Button>
        )}
      </Box>
      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {isLoadingData || isLoading ? (
          <Loader />
        ) : (
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'id', sort: 'desc' }],
              },
              pagination: { paginationModel: { pageSize: 25 } },
            }}
            rows={employees}
            columns={columns}
            pageSizeOptions={[10, 25, 50]}
          />
        )}
      </Box>
      {showRegister && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '20rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <RegisterCard
            reloadFunction={fetchEmployeesList}
            closeRegister={setShowRegister}
          />
        </Box>
      )}
      <Dialog open={areYouSure} onClose={handleAreYouSureClose}>
        <DialogTitle>
          {areYouSure2fa
            ? "Are you sure to delete this employee's 2fa secret?"
            : !areYouSure2fa && !areYouSure2faBool
            ? 'Are you sure to delete this employee ?'
            : otpBool && areYouSure2faBool
            ? 'Deactivate 2FA Authentication'
            : 'Activate 2FA Authentication'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {areYouSure2fa
              ? 'Secret will be deleted permanently'
              : !areYouSure2fa && !areYouSure2faBool
              ? 'Employee will be deleted permanently.'
              : otpBool && areYouSure2faBool
              ? 'Are you sure to deactivate 2FA Authentication ?'
              : 'Are you sure to activate 2FA Authentication ?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAreYouSureClose} color="inherit">
            Cancel
          </Button>
          <Button
            onClick={
              areYouSure2fa
                ? deleteEmployee2fa
                : !areYouSure2fa && !areYouSure2faBool
                ? deleteEmployee
                : otpBoolChange
            }
            color="inherit"
          >
            {otpBool && areYouSure2faBool
              ? 'Deactivate'
              : !otpBool && areYouSure2faBool
              ? 'Activate'
              : 'Delete'}
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ManageEmployees;
