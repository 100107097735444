import React, { useCallback, useEffect, useState } from 'react';
import { Box, Typography, useTheme } from '@mui/material';
import BlockIcon from '@mui/icons-material/Block';
import Header from '../../components/Header';
import { DataGrid } from '@mui/x-data-grid';
import { tokens } from '../../theme';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import api from '../../lib/axios';
import moment from 'moment';
import { useAuth } from '../../context/AuthContext';
import { verifyToken } from '../../lib/axios';
import { io } from 'socket.io-client';
import { Link } from 'react-router-dom';
import { useAlert } from '../../lib/useAlert';
import BlockUserForm from '../../components/BlockUserForm';
import Loader from '../../components/Loader';

const socket = io(process.env.REACT_APP_API_URL);

const BlockedUsers = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const token = localStorage.getItem('token');
  const [isLoading, setIsLoading] = useState(false);
  const columns = [
    {
      field: 'userId',
      headerName: 'User Id',
      flex: 0.6,
      renderCell: ({ row: { userId } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
          >
            <Link
              to={`https://backoffice.betconstruct.com/#/customers/detail/${userId}`}
              style={{ textDecoration: 'none', color: '#4caf50' }}
              target="_blank"
            >
              <Typography>{userId}</Typography>
            </Link>
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
    {
      field: 'username',
      headerName: 'Username',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'employee',
      headerName: 'Employee Name',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'blocked_at',
      headerName: 'Blocked At',
      flex: 0.7,
      disableClickEventBubbling: true,
    },
    {
      field: 'unblock',
      headerName: 'Unblock',
      flex: 0.2,
      renderCell: ({ row: { userId } }) => {
        return (
          <Box
            width={1}
            display="flex"
            justifyContent="flex-start"
            height={1}
            alignItems="center"
            sx={{
              cursor: 'pointer',
              '&:hover': {
                color: '#4bb543',
              },
            }}
            onClick={() => handleUnblock(userId)}
          >
            <BlockIcon />
          </Box>
        );
      },
      disableClickEventBubbling: true,
    },
  ];
  const [blockedUsers, setBlockedUsers] = useState([]);
  const [showBlockUser, setShowBlockUser] = useState(false);
  const { handleLogout } = useAuth();
  const showAlert = useAlert();

  // Handle Alerts
  const handleAlert = (message) => {
    showAlert(message, 'success');
  };

  const fetchBlockedUsers = useCallback(async () => {
    setIsLoading(true);
    const isTokenValid = await verifyToken(token);
    if (!isTokenValid) {
      handleLogout();
      return;
    }
    const response = await api.get('/api/blocked-users', {
      headers: {
        Authorization: token,
      },
    });
    const formattedBlockedUsers = response.data.map((request) => ({
      ...request,
      blocked_at: formatDate(request.blocked_at),
    }));
    setBlockedUsers(formattedBlockedUsers);
    setIsLoading(false);
  }, [token, handleLogout]);

  useEffect(() => {
    fetchBlockedUsers();

    socket.on('userBlocked', () => {
      fetchBlockedUsers();
    });
  }, [fetchBlockedUsers]);

  const handleUnblock = async (userId) => {
    try {
      const response = await api.post(
        '/api/unblock-user',
        {
          userId: userId,
        },
        {
          headers: {
            Authorization: token,
          },
        }
      );
      handleAlert(response.data.message);
      fetchBlockedUsers();
    } catch (error) {
      handleAlert('Error unblocking user, please try again.');
      console.error('Error blocking player:', error);
    }
  };

  const handleClickOpen = () => {
    setShowBlockUser(!showBlockUser);
  };

  const formatDate = (date) => moment(date).format('DD.MM.YYYY HH:mm:ss');
  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="Bonus History"
          subtitle="Last Bonus Requests"
          color={colors.greenAccent[400]}
          mt={0}
        />
        <Box
          sx={{
            backgroundColor: colors.grey[100],
            width: 35,
            height: 35,
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={() => handleClickOpen()}
        >
          {showBlockUser ? (
            <RemoveIcon sx={{ color: '#6870fa' }} />
          ) : (
            <AddIcon sx={{ color: '#6870fa' }} />
          )}
        </Box>
      </Box>
      <Box
        height="75vh"
        sx={{
          '& .MuiDataGrid-root': {
            border: 'none',
          },
          '& .MuiDataGrid-cell': {
            borderBottom: 'none',
          },
          '& .name-column--cell': {
            color: colors.greenAccent[300],
          },
          '& .MuiDataGrid-columnHeaders': {
            backgroundColor: colors.blueAccent[700],
            borderBottom: 'none',
          },
          '& .MuiDataGrid-virtualScroller': {
            backgroundColor: colors.primary[400],
          },
          '& .MuiDataGrid-footerContainer': {
            borderTop: 'none',
            backgroundColor: colors.blueAccent[700],
          },
          '& .MuiCheckbox-root': {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {isLoading ? (
          <Loader />
        ) : (
          <DataGrid
            checkboxSelection
            disableRowSelectionOnClick
            initialState={{
              sorting: {
                sortModel: [{ field: 'blocked_at', sort: 'asc' }],
              },
            }}
            rows={blockedUsers}
            columns={columns}
          />
        )}
      </Box>
      {showBlockUser && (
        <Box
          style={{
            padding: '16px',
            border: '1px solid grey',
            position: 'absolute',
            top: '50%',
            left: '50%',
            height: 'auto',
            width: '20rem',
            transform: 'translate(-50%, -50%)',
            backgroundColor: colors.primary[400],
            zIndex: 1000,
          }}
        >
          <BlockUserForm
            reloadFunction={fetchBlockedUsers}
            closeBlockUser={setShowBlockUser}
          />
        </Box>
      )}
    </Box>
  );
};

export default BlockedUsers;
