import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import api from '../lib/axios';
import { io } from 'socket.io-client';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [role, setRole] = useState(null);
  const [loading, setLoading] = useState(true); // Loading state to prevent premature redirects
  const socketRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      api
        .get('/api/auth/role', {
          headers: { Authorization: token },
        })
        .then((response) => {
          setRole(response.data.role);
          setIsAuthenticated(true);
          setLoading(false); // Set loading to false after fetching role
        })
        .catch(() => {
          setIsAuthenticated(false);
          setRole(null);
          setLoading(false); // Set loading to false even on error
          navigate('/login');
        });
    } else {
      setIsAuthenticated(false);
      setRole(null);
      localStorage.removeItem('employee');
      localStorage.removeItem('employeeId');
      setLoading(false); // Set loading to false if no token
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    if (loading) return;

    if (!isAuthenticated) {
      if (location.pathname !== '/login') {
        navigate('/login');
      }
    } else {
      if (
        role !== 'admin' &&
        (location.pathname === '/blocked-users' ||
          location.pathname === '/manage-employees' ||
          location.pathname === '/prize-list' ||
          location.pathname === '/bonus-types')
      ) {
        if (location.pathname !== '/') {
          console.log(
            'Navigating to / (non-admin tried to access a restricted page)'
          );
          navigate('/');
        }
      }
    }
  }, [isAuthenticated, role, loading, location.pathname, navigate]);

  useEffect(() => {
    const userId = localStorage.getItem('employeeId');
    const employeeName = localStorage.getItem('employee');
    if (isAuthenticated && userId && !socketRef.current) {
      socketRef.current = io(`${process.env.REACT_APP_API_URL}`, {
        query: { userId: userId, username: employeeName },
      });
      socketRef.current.on('connect', () => {});
      socketRef.current.on('disconnect', () => {});
    } else if (!isAuthenticated && socketRef.current) {
      socketRef.current.on('disconnect', () => {});
      socketRef.current.disconnect(); // Properly disconnect the socket
      socketRef.current = null; // Clear the reference to the socket
    }
  }, [isAuthenticated]);

  const handleLogin = (data) => {
    localStorage.setItem('token', data.token);
    localStorage.setItem('employee', data.name);
    localStorage.setItem('employeeId', data.id);
    setIsAuthenticated(true);
    setRole(data.role); // Set role immediately on login
    navigate('/');
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('employee');
    localStorage.removeItem('employeeId');
    setIsAuthenticated(false);
    setRole(null);
    navigate('/login');
  };

  const isAdmin = () => role === 'admin';

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{ isAuthenticated, role, isAdmin, handleLogin, handleLogout }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
